import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IDropdown } from 'interfaces/IDropdownField';
import { truncateString, truncateTooltipText } from 'utils/truncateString';

import styles from 'components/common/DropdownField/Dropdown.module.scss';
import { ExMenuItem, ExSelect, ExTooltip, TooltipAlignment, TooltipPosition } from '@boomi/exosphere';
import { TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';
import { IKeyStringValueString } from '../../../interfaces/IGeneral';

export const Dropdown: FC<IDropdown> = ({ options, title = '', required = false, selectedItem, handleChange, className, placeholder, applyOverride, callback, showTooltip = false }) => {
    const { dropdownWrapper } = styles;

    const [defaultValue, setDefaultValue] = useState<any>(options[0].label);

    const { t } = useTranslation();

    useEffect(() => {
        callback?.();
        setDefaultValue(selectedItem);
    }, []);
    useEffect(() => {
        setDefaultValue(selectedItem);
    }, [selectedItem]);

    function isLabelLong(el: IKeyStringValueString) {
        return t(`${el.label ? el.label : el.value}`);
    }

    return (
        <div className={`${dropdownWrapper} ${styles[className as string]}`}>
            <ExSelect
                label={t(title)}
                required={required}
                onChange={(e: any) => {
                    handleChange(e);
                }}
                selected={defaultValue}
                data-testid={`dropdownElement dropdown-${t(selectedItem).replace(/\s+/g, '-')}`}
                placeholder={placeholder}
                className={`${applyOverride ? 'ex-select-override' : ''}`}
                hideClearIcon={true}
                restrictMaxMenuHeight={true}
            >
                {options.map((el) =>
                    isLabelLong(el).length > TOOLTIP_MAX_LENGTH_ALLOWED ? (
                        <ExTooltip key={el.value} position={TooltipPosition.TOP} alignment={TooltipAlignment.END} className="componentExTooltip">
                            <div className="componentExTooltipDiv">{truncateTooltipText(isLabelLong(el))}</div>
                            <ExMenuItem slot={showTooltip ? 'anchor' : ''} value={el.value}>
                                {truncateString(isLabelLong(el))}
                            </ExMenuItem>
                        </ExTooltip>
                    ) : (
                        <ExMenuItem key={el.value} value={el.value}>
                            {isLabelLong(el)}
                        </ExMenuItem>
                    ),
                )}
            </ExSelect>
        </div>
    );
};

export default Dropdown;
