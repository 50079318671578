/* eslint-disable react/jsx-key */
import React from 'react';

import Spinner from 'components/common/Spinner/Spinner';
import GenericContainer from 'containers/GenericContainer';
import useDisplayEnvName from 'hooks/useDisplayEnvName';
import useDataMaps from 'hooks/useDataMaps';
import { ExAccordion, ExAccordionItem, ButtonSize, ExButton, ExTooltip, ButtonFlavor, ButtonType, TooltipPosition, TooltipAlignment, AccordionVariant } from '@boomi/exosphere';
import GenericTable from 'components/common/GenericTable/GenericTable';
import Dropdown from 'components/common/DropdownField/Dropdown';
import DialogBox from 'components/common/DialogBox/DialogBox';
import useAccordionToggle from 'hooks/useAccordionToggle';
import { truncateString, truncateTooltipText } from 'utils/truncateString';
import { TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';

const DataMapsInstall = () => {
    const {
        data,
        t,
        name,
        isLoading,
        handleClick,
        handleUninstall,
        processes,
        process,
        selectedIPack,
        setSelectedProcess,
        getCancelBtnLabel,
        getExtensionPageTitle,
        stepChangeWithOneExtension,
        setDefaultProcess,
    } = useDataMaps();
    const { envName } = useDisplayEnvName();
    const { accordionListStatus, handleAccordionToggle } = useAccordionToggle(data.length, process);
    return (
        <>
            <GenericContainer
                name={name}
                title={getExtensionPageTitle()}
                titleVariable={selectedIPack?.installationType.toLowerCase() === 'single' ? 'Single' : 'Multi'}
                titleVariable2={` : DataMaps - ${envName || ''}`}
            >
                <div className="ex-container">
                    <div className="ex-row">
                        <div className="ex-col-xs-7">
                            {isLoading || stepChangeWithOneExtension ? (
                                <Spinner />
                            ) : (
                                <>
                                    <div>
                                        {Boolean(processes.length) && (
                                            <div className="ex-select-override">
                                                <div className="generic-container-subtitle">{t('processDropdown.title')}</div>
                                                <Dropdown
                                                    options={processes}
                                                    title=""
                                                    contained={true}
                                                    handleChange={(e) => {
                                                        setSelectedProcess(e);
                                                    }}
                                                    selectedItem={process}
                                                    className="processDropdown"
                                                    placeholder={t('processDropdown.dataMapsPlaceHolder')}
                                                    applyOverride={true}
                                                    callback={setDefaultProcess}
                                                    showTooltip={true}
                                                />
                                            </div>
                                        )}

                                        {Boolean(process.length) && (
                                            <div className="generic-container-subtitle" style={{ marginTop: 'var(--exo-spacing-medium)' }}>
                                                {t('installPage.dataMaps')}
                                            </div>
                                        )}

                                        <ExAccordion variant={AccordionVariant.ELEVATED}>
                                            {Boolean(process.length) &&
                                                data?.map((item: any, i: number) => {
                                                    const datamapsLabel = item?.Result?.name;
                                                    return datamapsLabel.length > TOOLTIP_MAX_LENGTH_ALLOWED ? (
                                                        <ExTooltip key={item?.Result?.mapId} position={TooltipPosition.TOP} alignment={TooltipAlignment.START} className="componentExTooltip">
                                                            <div className="componentExTooltipDiv"> {`${truncateTooltipText(datamapsLabel)}`}</div>
                                                            <ExAccordionItem
                                                                variant={AccordionVariant.ELEVATED}
                                                                label={`${truncateString(datamapsLabel)}`}
                                                                slot="anchor"
                                                                open={accordionListStatus[i]}
                                                                onToggle={(e: CustomEvent) => handleAccordionToggle(e, i)}
                                                                data-testid={`datamap-accordion-${i}`}
                                                            >
                                                                <GenericTable data={item} />
                                                            </ExAccordionItem>
                                                        </ExTooltip>
                                                    ) : (
                                                        <ExAccordionItem
                                                            variant={AccordionVariant.ELEVATED}
                                                            key={item?.Result?.mapId}
                                                            label={`${truncateString(datamapsLabel)}`}
                                                            open={accordionListStatus[i]}
                                                            onToggle={(e: CustomEvent) => handleAccordionToggle(e, i)}
                                                            data-testid={`datamap-accordion-${i}`}
                                                        >
                                                            <GenericTable data={item} />
                                                        </ExAccordionItem>
                                                    );
                                                })}
                                        </ExAccordion>
                                    </div>
                                    <div className="generic-container-footer">
                                        <div className="generic-container-footer__actions">
                                            <ExButton
                                                role="button"
                                                slot="footer"
                                                type={ButtonType.PRIMARY}
                                                flavor={ButtonFlavor.BASE}
                                                size={ButtonSize.DEFAULT}
                                                onClick={handleClick}
                                                data-testid="btn-continue"
                                            >
                                                {t('btn.continue')}
                                            </ExButton>
                                            <ExButton
                                                role="button"
                                                slot="footer"
                                                type={ButtonType.SECONDARY}
                                                flavor={ButtonFlavor.BASE}
                                                size={ButtonSize.DEFAULT}
                                                onClick={handleUninstall}
                                                data-testid="btn-cancel"
                                            >
                                                {t(getCancelBtnLabel())}
                                            </ExButton>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </GenericContainer>
            <DialogBox />
        </>
    );
};

export default DataMapsInstall;
