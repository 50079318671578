import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AlertBox from 'components/common/AlertBox/AlertBox';
import UnavailableItems from 'components/common/BlankState/UnavailableItems';
import { IGenericTableWithCheckbox } from 'interfaces/ITableWithCheckbox';
import { IEnvironmentElement } from 'interfaces/IEnvironment';
import { ISourceProfileData, ITableItem } from 'interfaces/IGenericTable';
import styles from 'components/Form/Table/Table.module.scss';
import { ExIcon, ExInput, ExTable } from '@boomi/exosphere';
import { IGridReadyParams, ITableApi } from 'interfaces/IGrid';

export const TableWithCheckboxDataMaps: FC<IGenericTableWithCheckbox> = ({ data, title, extraClass, emptyErrorLabel, checkedValues, handleOnChange, searching, headerName }) => {
    const { t } = useTranslation();
    const { tableItem, tableLabel, gripTableWrapper } = styles;
    const [rowData, setRowData] = useState<ITableItem[]>([]);
    const [isDataRefresh, setIsDataRefresh] = useState(true);
    const [gridApi, setGridApi] = useState<ITableApi>({});

    useEffect(() => {
        setRowData(data.map((item: IEnvironmentElement | ISourceProfileData) => ({ ...item, targetFieldName: item.name })));
        setIsDataRefresh(false);
    }, []);

    const unavailableItems = !searching ? <AlertBox type="warning" message={emptyErrorLabel} /> : <UnavailableItems />;

    const gridOptions: any = useMemo(() => {
        const targetFieldRenderer = (params: any) => {
            const environment: IEnvironmentElement | ISourceProfileData = params.data;
            return environment.xpath
                ? `<ex-tooltip position="top" alignment="start" style="width:100%;z-index:9999999999;word-break:break-word;"><div style="white-space: initial;word-break: break-word;">
                      ${environment.name} (${environment.xpath})
                  </div><div slot="anchor" style="word-wrap: break-word;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;width: 100%;white-space: initial;" >  <span className="${tableItem} ${styles[extraClass]}">${environment.name}</span>
                  <span style="margin-left:0.5rem;opacity:0.7">(${environment.xpath})</span></div></ex-tooltip>`
                : `<div><span className="${tableItem} ${styles[extraClass]}">${environment.name}</span></div>`;
        };
        const columnDefs = [
            {
                headerName: t(headerName),
                field: 'targetFieldName',
                tooltipField: t(headerName),
                cellRenderer: targetFieldRenderer,
                headerCheckboxSelection: false,
                checkboxSelection: true,
            },
        ];
        return {
            defaultColDef: {
                sortable: false,
                resizable: false,
            },
            animateRows: true,
            columnDefs,
            rowData,
            rowHeight: 50,
            domLayout: 'autoHeight',
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            onGridReady: (params: IGridReadyParams) => {
                params.api.sizeColumnsToFit();
                setGridApi(params.api);
            },
            onGridSizeChanged: (params: IGridReadyParams) => {
                params.api.sizeColumnsToFit();
            },
            onSelectionChanged: (event: any) => {
                const rows = event.api.getSelectedRows();
                handleOnChange(rows);
            },
            onFirstDataRendered: (params: any) => {
                params.api.forEachNode((node: any) => {
                    const isSelected = Boolean(checkedValues[node.data?.xpath]);
                    return node.setSelected(isSelected);
                });
            },
            onRowDataChanged(params: any): void {
                params.api.getDisplayedRowAtIndex(0).setSelected(true);
            },
            popupParent: document.querySelector('.map-modal'),
            cacheQuickFilter: true,
        };
    }, [rowData, t]);

    return (
        <div className="map-modal">
            <ExInput
                placeholder={t('search.byFieldName')}
                type="text"
                clearable={true}
                className="search-dropdown-wrapper__input"
                data-testid="search-input"
                onInput={(e: Event) => {
                    const inputValue = (e.target as HTMLInputElement).value;
                    gridApi?.setGridOption('quickFilterText', inputValue);
                }}
                label={t('search.filterFields')}
            >
                <ExIcon hideBrowserTooltip={true} icon="magnifying-glass" label="save icon" slot="prefix"></ExIcon>
            </ExInput>
            <p className={tableLabel}>{t(title)}</p>
            {!data?.length ? (
                unavailableItems
            ) : (
                <div>
                    <div className={gripTableWrapper}>{!!data.length && !isDataRefresh && gridOptions && <ExTable gridOptions={gridOptions} overflowVisible={true}></ExTable>}</div>
                </div>
            )}
        </div>
    );
};

export default TableWithCheckboxDataMaps;
