import React, { useEffect, useState } from 'react';
import GenericContainer from 'containers/GenericContainer';
import { useNavigate } from 'react-router-dom';
import useEditInstall from '../../hooks/useEditInstall';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../interfaces/IStore';
import { useQueryParam } from '../../hooks/useQueryParam';
import AlertBox from 'components/common/AlertBox/AlertBox';
import DialogBox from 'components/common/DialogBox/DialogBox';
import useEditIpack from 'hooks/useEditIpack';
import useAttachedIpack from 'hooks/useAttachedIpack';
import Spinner from 'components/common/Spinner/Spinner';
import { setSelectedEnvironments } from 'store/actions/generalActions';
import useEditIpackTable from 'hooks/useEditIpackTable';
import { useTranslation } from 'react-i18next';
import EndpointError from 'components/common/EndpointError/EndpointError';
import { ButtonFlavor, ButtonType, ExButton, ExTable } from '@boomi/exosphere';
import './EditIpack.scss';
import { IGridReadyParams, ITableApi } from 'interfaces/IGrid';

const EditIpack = () => {
    const dispatch = useDispatch();
    const { id: integrationId } = useQueryParam();
    const ipackName = useSelector((state: IStore) => state.general.selectedIPack?.name);
    const { handleEditInstall, handleEditUninstallIpackInstance, handleDone } = useEditInstall();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { handleIpackEdit, handleUnattachIPackEnv } = useEditIpack();
    const { environmentsAll, isIpackAttachedToEnv, handleUninstallIntegration, isLoading: isEnvDataLoading } = useAttachedIpack();
    const { isLoading, rowDetails, isAnyEnvironmentMissingAtom, environments } = useEditIpackTable(environmentsAll);
    const [gridApi, setGridApi] = useState<ITableApi>({});
    const { networkError } = useSelector((state: IStore) => state.general);

    const handleScheduleAndRun = () => {
        const installedEnvs = rowDetails
            .filter((item: any) => item.isInstalled)
            .map((element: any) => ({ environmentId: element.id, id: element.id, name: element.environmentName, classification: element.classification }));
        dispatch(setSelectedEnvironments(installedEnvs));
        navigate(`/edit/multi/scheduleRun?id=${integrationId}`);
    };

    const getEnvironmentsTitle = (envs: any) => <div className="generic-container-subtitle">{t('editIpack.environments', { count: envs.length })}</div>;
    const environmentRenderer = (params: any) => {
        const environment: any = params.data;
        return `<ex-tooltip position="top" alignment="start"><span >${environment.environmentName}</span><span slot="anchor">${environment.environmentName}</span></ex-tooltip>`;
    };
    const atomRenderer = (params: any) => {
        const environment: any = params.data;

        if (isAnyEnvironmentMissingAtom) {
            if (environment.attachmentStatus === 'yes') {
                return `
                    <span>
                        <ex-tooltip position="top" alignment="start">
                            <div>${t('alert.atomsAttached')}</div>
                            <ex-icon hide-browser-tooltip="true" icon="status-success" style="font-size: 150%;align-items: center;" variant="secondary" slot="anchor"></ex-icon>
                        </ex-tooltip>
                    </span>
                `;
            } else {
                return `
                    <span>
                        <ex-tooltip position="top" alignment="start">
                            <div>${t('alert.noEnvironmentAtomInEditIpackPage')}</div>
                            <ex-icon hide-browser-tooltip="true" icon="status-warning" style="font-size: 150%;align-items: center;" variant="danger" slot="anchor"></ex-icon>
                        </ex-tooltip>
                    </span>
                `;
            }
        }
        return '';
    };

    const actionsRenderer = (params: any) => {
        const wrapper = document.createElement('div');

        const environment = params.data;
        const { id, environmentName, classification, isInstalled } = environment;

        if (isInstalled) {
            const editButton = document.createElement('span');
            editButton.innerText = 'Edit';
            editButton.style.textDecoration = 'underline';
            editButton.style.fontWeight = 'var(--exo-font-weight-semi-bold)';
            editButton.style.cursor = 'pointer';
            editButton.style.marginRight = '1rem';
            editButton.style.color = 'var(--exo-color-font-link)';
            editButton.addEventListener('click', () => {
                handleIpackEdit({
                    environmentId: id,
                    id,
                    name: environmentName,
                    classification,
                });
            });

            const uninstallButton = document.createElement('span');
            uninstallButton.innerText = 'Uninstall';
            uninstallButton.style.textDecoration = 'underline';
            uninstallButton.style.fontWeight = 'var(--exo-font-weight-semi-bold)';
            uninstallButton.style.cursor = 'pointer';
            uninstallButton.style.color = 'var(--exo-color-font-link)';
            uninstallButton.addEventListener('click', () => {
                handleUnattachIPackEnv(
                    {
                        environmentId: id,
                        id,
                        name: environmentName,
                    },
                    rowDetails,
                );
            });

            wrapper.appendChild(editButton);
            wrapper.appendChild(uninstallButton);
        } else {
            const installButton = document.createElement('span');
            installButton.innerText = 'Install';
            installButton.style.textDecoration = 'underline';
            installButton.style.fontWeight = 'var(--exo-font-weight-semi-bold)';
            installButton.style.cursor = 'pointer';
            installButton.style.color = 'var(--exo-color-font-link)';
            installButton.addEventListener('click', () => {
                handleEditInstall({
                    environmentId: id,
                    id,
                    name: environmentName,
                });
            });

            wrapper.appendChild(installButton);
        }

        return wrapper;
    };

    const createColumnDefs = () => {
        const columnDefs = [
            {
                headerName: t('editIpack.columns.environmentName'),
                sortable: false,
                tooltipField: t('editIpack.columns.environmentName'),
                field: 'environmentName',
                cellRenderer: environmentRenderer,
            },
            {
                headerName: t('editIpack.columns.installStatus'),
                field: 'installStatus',
                sortable: false,
            },
            {
                headerName: t('editIpack.columns.actions'),
                field: 'isInstalled',
                sortable: false,
                cellRenderer: actionsRenderer,
            },
        ];

        if (isAnyEnvironmentMissingAtom) {
            columnDefs.splice(1, 0, {
                headerName: t('editIpack.columns.attachmentStatus'),
                field: 'attachmentStatus',
                sortable: false,
                cellRenderer: atomRenderer,
                tooltipField: '',
            });
        }

        return columnDefs;
    };

    const gridOptions: any = {
        defaultColDef: {
            sortable: false,
            resizable: false,
        },
        animateRows: true,
        suppressRowTransform: true,
        columnDefs: createColumnDefs(),
        rowData: rowDetails,
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        rowHeight: 48,
        suppressPaginationPanel: true,
        paginationPageSize: 2,
        onGridReady: (e: any) => {
            e.api.sizeColumnsToFit();
            setGridApi(e.api);
        },
        onGridSizeChanged: (params: IGridReadyParams) => {
            params.api.sizeColumnsToFit();
        },
        popupParent: document.querySelector(`body`),
    };

    useEffect(() => {
        if (Object.keys(gridApi).length) {
            gridApi.setGridOption('rowData', rowDetails);
        }
    }, [rowDetails, gridApi]);

    if (networkError) {
        return (
            <GenericContainer title={t('editIpack.title')} name={ipackName}>
                <div>
                    <EndpointError message="error.500.editIpack" type="danger" />
                    <div className="edit-footer generic-container-footer generic-container-footer__actions">
                        <ExButton className="ex-mr-standard" flavor={ButtonFlavor.BASE} onClick={handleDone} data-testid="editIPackModal-done">
                            {t('btn.done')}
                        </ExButton>
                    </div>
                </div>
            </GenericContainer>
        );
    }

    return (
        <>
            <GenericContainer title={t('editIpack.title')} name={ipackName}>
                {isLoading || isEnvDataLoading ? (
                    <Spinner />
                ) : (
                    <div className="ex-container">
                        <div className="ex-row">
                            <div className="ex-col-xs-8">
                                <form className="edit">
                                    {!isIpackAttachedToEnv && (
                                        <div className="ex-mt-medium ex-mb-medium">
                                            <AlertBox type="warning" message="alert.noEnvironmentAttachedInEditIpackPage" />
                                            <div className="edit-footer ex-mt-3x-large">
                                                <ExButton className="ex-mr-standard" flavor={ButtonFlavor.BASE} onClick={handleUninstallIntegration} data-testid="editIPackModal-done">
                                                    {t('btn.done')}
                                                </ExButton>
                                            </div>
                                        </div>
                                    )}
                                    {isIpackAttachedToEnv && getEnvironmentsTitle(environments)}
                                    {isIpackAttachedToEnv && <ExTable gridOptions={gridOptions} data-testid="spaces-table" overflowVisible={true} />}
                                    {isIpackAttachedToEnv && (
                                        <div className="edit-footer generic-container-footer generic-container-footer__actions">
                                            {
                                                <ExButton flavor={ButtonFlavor.BASE} onClick={handleDone} data-testid="editIPackModal-done">
                                                    {t('btn.done')}
                                                </ExButton>
                                            }
                                            <ExButton flavor={ButtonFlavor.BASE} type={ButtonType.SECONDARY} onClick={handleScheduleAndRun} data-testid="editIPackModal-scheduleAndRun">
                                                {t('btn.scheduleAndRun')}
                                            </ExButton>
                                            <ExButton flavor={ButtonFlavor.RISKY} type={ButtonType.SECONDARY} onClick={handleEditUninstallIpackInstance} data-testid="editIPackModal-uninstallAll">
                                                {t('btn.uninstallAll')}
                                            </ExButton>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                <DialogBox />
            </GenericContainer>
        </>
    );
};

export default EditIpack;
