import React from 'react';

import GenericContainer from 'containers/GenericContainer';
import DialogBox from 'components/common/DialogBox/DialogBox';
import ScheduleBlock from 'pages/Schedule/ScheduleBlock';
import useSetSchedule from 'hooks/useSetSchedule';
import { truncateString, truncateTooltipText } from 'utils/truncateString';
import {
    AccordionVariant,
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    ExAccordion,
    ExAccordionItem,
    ExButton,
    ExIcon,
    ExLoader,
    ExTooltip,
    LoaderSize,
    TooltipAlignment,
    TooltipPosition,
} from '@boomi/exosphere';
import styles from 'pages/Schedule/SetSchedule.module.scss';
import { TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';

const SetSchedule = () => {
    const {
        scheduleBlocks,
        scheduleBlocksToSend,
        setScheduleBlocks,
        setScheduleBlocksToSend,
        setCount,
        t,
        ipackName,
        envName,
        handleClick,
        name,
        handleCancel,
        handleSave,
        handleAccordionToggle,
        alreadyScheduledBlocksToSend,
        setAlreadyScheduledBlocksToSend,
        isLoading,
    } = useSetSchedule();
    const { blocksWrapper, wrapperActions, dividerline } = styles;
    const renderScheduleBlock = () => {
        return scheduleBlocks.map((el) => {
            const index = parseInt(el);
            return (
                <ExAccordionItem variant={AccordionVariant.ELEVATED} key={index} label={t(`scheduleBlock.schedule`, { number: index + 1 })} onToggle={() => handleAccordionToggle(index)}>
                    <ScheduleBlock
                        index={parseInt(el)}
                        {...{
                            scheduleBlocks,
                            setScheduleBlocks,
                            scheduleBlocksToSend,
                            setScheduleBlocksToSend,
                            alreadyScheduledBlocksToSend,
                            setAlreadyScheduledBlocksToSend,
                            setCount,
                        }}
                    />
                </ExAccordionItem>
            );
        });
    };
    
    if (isLoading) {
        return (
            <div className="browse-loader">
                <ExLoader size={LoaderSize.LARGE} inline={false} />
            </div>
        );
    } else {
        return (
            <>
                <GenericContainer name={t('setSchedule.name', { ipackName })} title={t('setSchedule.title', { processTitle: envName })}>
                    <div className={'generic-container-subtitle ex-mb-small'}>
                        {name.length > TOOLTIP_MAX_LENGTH_ALLOWED ? (
                            <ExTooltip position={TooltipPosition.TOP} alignment={TooltipAlignment.START} className="componentExTooltip" data-testid="setSchedule-tooltip">
                                <div className="componentExTooltipDiv">{`${truncateTooltipText(name)}`}</div>
                                <div slot="anchor"> {`${truncateString(name)}`}</div>
                            </ExTooltip>
                        ) : (
                            <div> {name}</div>
                        )}
                    </div>
                    <div className={wrapperActions}>
                        <ExButton flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} type={ButtonType.PRIMARY} onClick={handleClick} data-testid="setSchedule-addSchedule">
                            <ExIcon hideBrowserTooltip={true} icon="plus" slot="prefix" />
                            {t('setSchedule.addSchedule')}
                        </ExButton>
                        <ExButton type={ButtonType.SECONDARY} flavor={ButtonFlavor.BASE} size={ButtonSize.DEFAULT} onClick={handleSave} data-testid="setSchedule-save">
                            {t('setSchedule.save')}
                        </ExButton>
                        <ExButton type={ButtonType.SECONDARY} flavor={ButtonFlavor.BRANDED} size={ButtonSize.DEFAULT} onClick={handleCancel}>
                            {t('setSchedule.cancel')}
                        </ExButton>
                    </div>
                    <hr className={dividerline} />
                    <div className={blocksWrapper}>
                        <ExAccordion data-testid="setSchedule-accordion" variant={AccordionVariant.ELEVATED}>
                            {renderScheduleBlock()}
                        </ExAccordion>
                    </div>
                </GenericContainer>
                <DialogBox />
            </>
        );
    }
};

export default SetSchedule;