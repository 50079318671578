import React from 'react';

import DialogBox from 'components/common/DialogBox/DialogBox';
import DefaultInputField from 'components/Form/DefaultInputField/DefaultInputField';
import GenericContainer from 'containers/GenericContainer';
import useProperties from 'hooks/useProperties';
import { IProcessProperty, IPropertyField } from 'interfaces/IProcessProperty';
import { truncateString, truncateTooltipText } from 'utils/truncateString';

import Dropdown from 'components/common/DropdownField/Dropdown';
import { ExAccordion, ExAccordionItem, ExButton, ExTooltip, TooltipAlignment, TooltipPosition, ButtonType, ButtonFlavor, ButtonSize, AccordionVariant } from '@boomi/exosphere';
import Spinner from 'components/common/Spinner/Spinner';
import { TOOLTIP_MAX_LENGTH_ALLOWED } from 'constants/general';
import styles from './ConfirmationPage/ConfirmationPage.module.scss'

const Properties = () => {
    const {
        property,
        selectedIPack,
        onSubmit,
        envName,
        modalStatus,
        register,
        handleSubmit,
        errors,
        setValue,
        t,
        handleUninstall,
        processes,
        setSelectedProcess,
        process,
        getValues,
        reset,
        getPropertiesFormData,
        getCancelBtnLabel,
        getExtensionPageTitle,
        isLoading,
        stepChangeWithOneExtension,
        setDefaultProcess,
    } = useProperties();
    const { sub_title } = styles;

    function getPropertiesDiv(propertyItem: IProcessProperty, index: number) {
        return (
            <div>
                {propertyItem.ProcessPropertyValue?.map((field: IPropertyField) => {
                    const { type, key, value, label, encryptedValueSet } = field;
                    const constructedField = {
                        inputName: `${index}.${label}`,
                        label: label,
                        fieldType: type,
                        hiddenValue: encryptedValueSet,
                    };
                    return (
                        <DefaultInputField
                            key={key}
                            field={constructedField}
                            {...{
                                defaultValue: value || '',
                                register,
                                errors,
                                setValue,
                            }}
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <>
            <GenericContainer
                name={selectedIPack?.name}
                title={getExtensionPageTitle()}
                titleVariable={selectedIPack?.installationType.toLowerCase() === 'single' ? 'Single' : 'Multi'}
                titleVariable2={` : Properties - ${envName || ''}`}
            >
                <div className="ex-container">
                    <div className="ex-row">
                        <div className="ex-col-xs-7">
                            {isLoading || stepChangeWithOneExtension ? (
                                <Spinner />
                            ) : (
                                <form>
                                    {Boolean(processes.length) && (
                                        <>
                                            <div className="generic-container-subtitle">{t('processDropdown.title')}</div>
                                            <Dropdown
                                                options={processes}
                                                title=""
                                                contained={true}
                                                handleChange={(e) => {
                                                    getPropertiesFormData({ ...getValues() }, process);
                                                    setSelectedProcess(e);
                                                    reset();
                                                }}
                                                selectedItem={process}
                                                className="processDropdown"
                                                placeholder={t('processDropdown.propertiesPlaceHolder')}
                                                callback={setDefaultProcess}
                                                showTooltip={true}
                                            />
                                        </>
                                    )}
                                    {Boolean(process.length) && <div className={`${sub_title} ex-mb-standard`}>{t('installPage.properties')}</div>}
                                    <ExAccordion variant={AccordionVariant.ELEVATED}>
                                        {Boolean(property) &&
                                            Boolean(process.length) &&
                                            property.map((propertyItem: IProcessProperty, index: number) => {
                                                return propertyItem.name.length > TOOLTIP_MAX_LENGTH_ALLOWED ? (
                                                    <ExTooltip
                                                        key={propertyItem.id}
                                                        position={TooltipPosition.TOP}
                                                        alignment={TooltipAlignment.START}
                                                        className="componentExTooltip"
                                                        data-testid="properties-tooltip"
                                                    >
                                                        <div className="componentExTooltipDiv">{truncateTooltipText(propertyItem.name)}</div>
                                                        <ExAccordionItem label={`${truncateString(propertyItem.name)}`} open={index === 0} style={{fontSize:'var(--exo-spacing-2x-large)'}} variant={AccordionVariant.ELEVATED}>
                                                            {getPropertiesDiv(propertyItem, index)}
                                                        </ExAccordionItem>
                                                    </ExTooltip>
                                                ) : (
                                                    <ExAccordionItem variant={AccordionVariant.ELEVATED} key={propertyItem.id} label={`${propertyItem.name}`} open={index === 0} style={{fontSize:'var(--exo-spacing-2x-large)'} }>
                                                        {getPropertiesDiv(propertyItem, index)}
                                                    </ExAccordionItem>
                                                );
                                            })}
                                    </ExAccordion>
                                    <div className="generic-container-footer">
                                        <div className="generic-container-footer__actions">
                                            <ExButton
                                                role="button"
                                                slot="footer"
                                                type={ButtonType.PRIMARY}
                                                flavor={ButtonFlavor.BASE}
                                                size={ButtonSize.DEFAULT}
                                                onClick={() => handleSubmit(onSubmit)()}
                                                data-testid="btn-continue"
                                            >
                                                {t('btn.continue')}
                                            </ExButton>
                                            <ExButton
                                                role="button"
                                                slot="footer"
                                                type={ButtonType.SECONDARY}
                                                flavor={ButtonFlavor.BASE}
                                                size={ButtonSize.DEFAULT}
                                                onClick={handleUninstall}
                                                data-testid="btn-cancel"
                                            >
                                                {t(getCancelBtnLabel())}
                                            </ExButton>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </GenericContainer>
            {modalStatus && <DialogBox />}
        </>
    );
};

export default Properties;
