export const SCHEDULE_INTERVAL_OPTIONS = [
    {value: 'Minutes', description: 'scheduleBlock.description.minutes'},
    {value: 'Hour', description: 'scheduleBlock.description.hour'},
    {value: 'Day',  description: 'scheduleBlock.description.day'},
]

export const DEFAULT_HOURS = [
    {value: '08'},
    {value: '23'},
]

export const DEFAULT_MINUTES = [
    {value: '00'},
    {value: '59'},
]

export const SCHEDULE_HOURS_OPTIONS = [
    {value: '00'},
    {value: '01'},
    {value: '02'},
    {value: '03'},
    {value: '04'},
    {value: '05'},
    {value: '06'},
    {value: '07'},
    {value: '08'},
    {value: '09'},
    {value: '10'},
    {value: '11'},
    {value: '12'},
    {value: '13'},
    {value: '14'},
    {value: '15'},
    {value: '16'},
    {value: '17'},
    {value: '18'},
    {value: '19'},
    {value: '20'},
    {value: '21'},
    {value: '22'},
    {value: '23'},
]

export const SCHEDULE_MINUTES_OPTIONS = [
    { value: '00' },
    { value: '01' },
    { value: '02' },
    { value: '03' },
    { value: '04' },
    { value: '05' },
    { value: '06' },
    { value: '07' },
    { value: '08' },
    { value: '09' },
    { value: '10' },
    { value: '11' },
    { value: '12' },
    { value: '13' },
    { value: '14' },
    { value: '15' },
    { value: '16' },
    { value: '17' },
    { value: '18' },
    { value: '19' },
    { value: '20' },
    { value: '21' },
    { value: '22' },
    { value: '23' },
    { value: '24' },
    { value: '25' },
    { value: '26' },
    { value: '27' },
    { value: '28' },
    { value: '29' },
    { value: '30' },
    { value: '31' },
    { value: '32' },
    { value: '33' },
    { value: '34' },
    { value: '35' },
    { value: '36' },
    { value: '37' },
    { value: '38' },
    { value: '39' },
    { value: '40' },
    { value: '41' },
    { value: '42' },
    { value: '43' },
    { value: '44' },
    { value: '45' },
    { value: '46' },
    { value: '47' },
    { value: '48' },
    { value: '49' },
    { value: '50' },
    { value: '51' },
    { value: '52' },
    { value: '53' },
    { value: '54' },
    { value: '55' },
    { value: '56' },
    { value: '57' },
    { value: '58' },
    { value: '59' },
];


export const DAYS_DATA = [
    {value: 'daysBlock.monday'},
    {value: 'daysBlock.tuesday'},
    {value: 'daysBlock.wednesday'},
    {value: 'daysBlock.thursday'},
    {value: 'daysBlock.friday'},
    {value: 'daysBlock.saturday'},
    {value: 'daysBlock.sunday'},
]