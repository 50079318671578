import { IConnectionsArr } from 'interfaces/IConfigureConnection';
import { IKeyStringValueString } from 'interfaces/IGeneral';
import { IProcessExtensions } from 'interfaces/IProcesses';
import { IStore } from 'interfaces/IStore';
import { useDispatch, useSelector } from 'react-redux';
import { setSeletedExtensionProcess } from 'store/actions/generalActions';

const useProcessExtensions = (): IProcessExtensions => {
    const { extensionsProcessess, extensions } = useSelector((state: IStore) => state.general);
    const dispatch = useDispatch();
    const { selectedProcess, processes, instanceProcesses } = extensionsProcessess;

    const getProcessesForExtension = (data: any, extensionType: string) => {
        const processList: IKeyStringValueString[] = [];
        if (extensionType === 'DATA_MAPS') {
            data?.forEach((envMap: any) => {
                envMap?.environmentMapExtension?.forEach((processMaps: any) => {
                    const extensionsArr = processMaps?.length && JSON.parse(processMaps)?.response;
                    extensionsArr &&
                        extensionsArr?.forEach((extension: any) => {
                            const processData = instanceProcesses.find((item: any) => item.value === extension?.Result?.extensionGroupId);
                            processData && processList.findIndex((processItem) => processItem.value === processData.value) == -1 && processList.push(processData);
                        });
                });
            });
        } else {
            data?.forEach((envData: any) => {
                envData?.environmentExtensions.forEach((queryResult: any) => {
                    const resultData = JSON.parse(queryResult as unknown as string);
                    resultData?.result?.forEach((element: any) => {
                        if (extensionType === 'CONNECTION') {
                            getConnectionsProcesses(element, processList);
                        }
                        if (extensionType === 'PROCESS_PROPERTY') {
                            getProcessPropertyProcesses(element, processList);
                        }
                    });
                });
            });
        }
        return processList;
    };

    const getConnectionsProcesses = (element: any, processList: IKeyStringValueString[]) => {
        if (element?.connections?.connection) {
            const processData = instanceProcesses.find((item: any) => item.value === element.extensionGroupId);
            processData && processList.findIndex((processItem) => processItem.value === processData.value) == -1 && processList.push(processData);
        }
    };

    const getProcessPropertyProcesses = (element: any, processList: IKeyStringValueString[]) => {
        if (element?.processProperties?.ProcessProperty) {
            const processData = instanceProcesses.find((item: any) => item.value === element.extensionGroupId);
            processData && processList.findIndex((processItem) => processItem.value === processData.value) == -1 && processList.push(processData);
        }
    };

    const getExtensionsData = (data: any, extensionType: string) => {
        const extensionsStateArr: IConnectionsArr['result'] = [];
        data?.forEach((envData: any) => {
            envData?.environmentExtensions.forEach((queryResult: any) => {
                const resultData = JSON.parse(queryResult as unknown as string);
                resultData?.result?.forEach((element: any) => {
                    const extensionIndex = extensions.findIndex((val) => val.id === element.id && val.environmentId === element.environmentId);
                    if (extensionType === 'CONNECTION' && element?.connections?.connection && extensionIndex === -1) {
                        extensionsStateArr.push(element);
                    }
                    if (extensionType === 'PROCESS_PROPERTY' && element?.processProperties?.ProcessProperty && extensionIndex === -1) {
                        extensionsStateArr.push(element);
                    }
                });
            });
        });
        return [...extensionsStateArr, ...extensions];
    };

    const setSelectedProcess = (e: any) => {
        dispatch(setSeletedExtensionProcess(e.detail.value));
    };

    return {
        setSelectedProcess,
        process: selectedProcess,
        processes,
        getProcessesForExtension,
        getExtensionsData,
    };
};

export default useProcessExtensions;
